<template>
  <div>
    <template v-if="sepet.k_no && sepet.sepet_urunler.length">
      <b-overlay :show="show" spinner-variant="primary" spinner-type="grow">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12" md="8">
                <SepetUrunleri :sepet="sepet" />
                <MisafirBilgileri class="mt-3" :sepet="sepet" />
                <RezervasyonNotu class="mt-3" :sepet="sepet" />
                <!-- <HotelBilgisi class="mt-3" v-if="hasSepetUrunIcindeVarmi" :sepet="sepet" /> -->
                <OdemeBilgisi class="mt-3" :sepet="sepet" />
              </b-col>
              <b-col cols="12" md="4">
                <SepetDetayInfo :rezervasyon="sepet" />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <b-alert :show="true" variant="warning" class="text-center">
            <h1>UYARI !!</h1>
            <p>Sepette Gösterilecek Ürün Bulunmamaktadır</p>
            <router-link to="/rezervasyon/urunler" class="text-body"> Yeni Ürün Eklemek için Tıkla</router-link>
          </b-alert>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { ref, computed, defineComponent } from '@vue/composition-api';
import SepetUrunleri from './component/sepet/SepetUrunleri.vue';
import MisafirBilgileri from './component/sepet/MisafirBilgileri.vue';
import RezervasyonNotu from './component/sepet/RezervasyonNotu.vue';
import OdemeBilgisi from './component/sepet/OdemeBilgisi.vue';
import SepetDetayInfo from './component/sepet/SepetDetayInfo.vue';
import HotelBilgisi from './component/sepet/HotelBilgisi.vue';
import axiosIns from '@/libs/axios';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    SepetUrunleri,
    MisafirBilgileri,
    RezervasyonNotu,
    OdemeBilgisi,
    SepetDetayInfo,
    HotelBilgisi,
  },
  setup(_, context) {
    const expo = {};
    const { router } = useRouter();
    const toast = useToast();
    expo.show = ref(false);
    expo.sepet = computed(() => store.getters.getDirekRezervasyon);

    expo.hasSepetUrunIcindeVarmi = computed(() => {
      return expo.sepet.value.sepet_urunler.some((x) => x.urun_tipi == 'tur' || x.urun_tipi == 'arac_kira');
    });

    expo.onSubmit = () => {
      context.emit('show', true);

      expo.sepet.value.satis_yontemi = 'direk';
      expo.sepet.value.modul = 'yonetim';

      axiosIns.post('/travel/rezervasyon-ekle', expo.sepet.value).then((res) => {
        if (res.data.success) {
          const data = res.data;
          if (data.payment == 'kredi_karti' && data.threeDSHtmlContent) {
            document.documentElement.innerHTML = data.threeDSHtmlContent;
            document.getElementsByTagName('form')[0].submit();
          } else {
            toast.success('Yeni Rezervasyon Ekleme Başarılı.', {
              position: 'bottom-left',
            });
            store.commit('SET_SEPET_DIREK_REZERVASYON_RESET');
            router.push({ name: 'rezervasyon-urunler' });
          }
          context.emit('show', false);
        } else {
          toast.warning(res.data.message);
          context.emit('show', false);
        }
      });
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
