<template>
  <tr class="w-100">
    <td class="d-flex align-items-center pt-3">
      <b-form-checkbox size="lg" v-model="hizmet.check" @change="handlerHizmetCheck($event)" />
      {{ hizmet.icerik[defaultDil].baslik }}
    </td>
    <td class="pt-3">
      <span>{{ hizmet.hizmet_tipi }}</span>
      <span class="text-primary" v-if="hizmet.fiyat > 0">
        ( {{ hizmet.fiyat | exc(sepet.sabit_kur) }} {{ exchange.banka_kodu }} )
      </span>
      <span class="text-primary" v-else> ( Ücretsiz )</span>
    </td>
    <td>
      <flat-pickr
        v-if="hizmet.hizmet_tipi != 'adet'"
        class="form-control rounded-0"
        :class="hizmet.check && 'bg-white'"
        v-model="hizmet.gunler"
        :disabled="!hizmet.check"
        :config="config"
        placeholder="Günler"
        :events="config.events"
        @on-change="handlerDateSelected($event, hizmet.hizmet_tipi, hizmet.fiyat)"
        name="date"
      />
      <b-form-group class="pt-1" label-for="pax" v-else>
        <b-input-group size="sm">
          <template #prepend>
            <b-button squared variant="danger" @click="hadlerAdetChange(false)" :disabled="!hizmet.check"> - </b-button>
          </template>
          <b-form-input class="text-center rounded-0" style="width: 0px" v-model="hizmet.adet" :disabled="!hizmet.check" />
          <template #append>
            <b-button squared variant="success" @click="hadlerAdetChange(true)" :disabled="!hizmet.check"> + </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </td>
    <td class="pt-3 text-right" v-if="hizmet.hizmet_tipi != 'adet'">{{ hizmet.gun }} {{ $t('gun') }}</td>
    <td class="pt-3 text-right text-primary" style="min-width: 90px">
      {{ hizmet.toplam_fiyat | exc(sepet.sabit_kur) }} {{ exchange.banka_kodu }}
    </td>
  </tr>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { ref, computed, defineComponent, toRefs, watch } from '@vue/composition-api';
import { exChange } from '@/libs/global';
export default defineComponent({
  components: { flatPickr },
  props: {
    product: { type: Object },
    hizmet: { type: Object },
    tarih: { type: [Object, String] },
    sepet: { type: Object },
  },
  setup(props) {
    const expo = {};
    const { product, hizmet, sepet } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exchange = computed(() => store.getters.getExchange);

    expo.config = ref({
      events: ['onChange'],
      inline: false,
      mode: 'multiple',
      minDate: 'today',
      maxDate: 'today',
      monthSelectorType: 'static',
      dateFormat: 'Y-m-d',
      wrap: false, // set wrap to true only when using 'input-group'
      altInput: false,
      defaultDil: Turkish,
    });

    expo.handlerHizmetCheck = (event) => {
      hizmet.value.check = event;
      if (hizmet.value.check == false) {
        hizmet.value.gun = 0;
        hizmet.value.gunler = [];
        hizmet.value.toplam_fiyat = 0;
      }
    };

    expo.handlerDayChange = (tipi, gun, fiyat) => {
      if (gun > 0) {
        if (tipi == 'gunluk') {
          hizmet.value.gun = gun;
          hizmet.value.toplam_fiyat = gun * fiyat;
          hizmet.value.kur_toplam_fiyat = exChange(hizmet.value.toplam_fiyat, sepet.value.sabit_kur);
        } else if (tipi == 'tek_sefer') {
          if (hizmet.value.check == true) {
            hizmet.value.gun = gun;
            hizmet.value.toplam_fiyat = gun * fiyat;
            hizmet.value.kur_toplam_fiyat = exChange(hizmet.value.toplam_fiyat, sepet.value.sabit_kur);
          }
        } else {
          hizmet.value.toplam_fiyat = gun * fiyat;
          hizmet.value.kur_toplam_fiyat = exChange(hizmet.value.toplam_fiyat, sepet.value.sabit_kur);
        }
      } else {
        hizmet.value.toplam_fiyat = 0;
        hizmet.value.kur_toplam_fiyat = 0;
      }
    };

    expo.handlerDateSelected = (event, tipi, fiyat) => {
      if (tipi == 'gunluk') {
        expo.handlerDayChange(tipi, event.length, fiyat);
      } else if (tipi == 'tek_sefer') {
        if (hizmet.value.check == true) {
          expo.handlerDayChange(tipi, event.length, fiyat);
        }
      } else {
        if (hizmet.value.check == true) {
          expo.handlerDayChange(tipi, event, fiyat);
        }
      }
    };

    expo.hadlerAdetChange = (count) => {
      if (count) {
        hizmet.value.adet++;
      } else {
        hizmet.value.adet--;
        if (hizmet.value.adet <= 0) hizmet.value.adet = 0;
      }
      if (hizmet.value.adet >= 0) {
        expo.handlerDateSelected(hizmet.value.adet, 'adet', hizmet.value.fiyat);
      }
    };

    watch(
      sepet,
      (val) => {
        if (product.value.urun_tipi == 'villa' || product.value.urun_tipi == 'yat') {
          const tarih = product.value.sepet_tarihi;
          expo.config.value.mode = hizmet.value.hizmet_tipi == 'tek_sefer' ? 'single' : 'multiple';
          expo.config.value.minDate = moment(tarih.giris, 'YYYY-MM-DD').format('YYYY-MM-DD');
          expo.config.value.maxDate = moment(tarih.cikis, 'YYYY-MM-DD').format('YYYY-MM-DD');
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
