<template>
  <tr>
    <td colspan="5" class="p-1">
      <b-overlay :show="loaded" class="w-100">
        <!-- <template v-if="product.urun_tipi == 'villa'">
          <table class="table table-striped p-0 m-0 border-top" :class="product.ekstra_hizmet_sec && 'border border-primary'">
            <tr>
              <td class="product-image" style="min-width: 90px">
                  <nuxt-img
                    thumbnail
                    format="webp"
                    loading="lazy"
                    fit="cover"
                    quality="100"
                    width="100"
                    :src="product.gorseller | sepetImage(product.k_no, 'urun')"
                    :alt="product.baslik[defaultDil]"
                  />
              </td>
              <td class="product-title" style="min-width: 240px">
                <div class="product-name"><i class="fad fa-box-full" /> {{ product.baslik[defaultDil] }}</div>
                <div class="product-section">
                  <i class="fad fa-ticket" v-b-tooltip.hover.top="'Bilet No'"></i> : <strong>{{ product.bilet_no }}</strong>
                </div>
                <div class="product-section">
                  <i class="fad fa-stream" v-b-tooltip.hover.top="'Ürün Kodu'"></i> :
                  <strong>{{ product.urun_kodu }}</strong>
                </div>
              </td>
              <td class="product-pax" style="min-width: 230px; font-size: 14px; font-weight: 600">
                <div class="product-name">
                 Giriş Tarihi :<strong>{{ product.rezervasyon_tarihi.giris | moment }}</strong>
                </div>
                <div class="product-name">
                  Çıkış Tarihi :<strong>{{ product.rezervasyon_tarihi.cikis | moment }}</strong>
                </div>
                <div class="product-section">
                 Konaklama Süresi : <strong>{{ product.toplam_gun }} Gün</strong>
                </div>
                <div class="product-section">
                  Kişi Sayısı :
                  <strong>{{ product.pax.yetiskin + product.pax.cocuk + product.pax.bebek }} Kişi</strong>
                </div>
              </td>
              <td class="product-total" style="min-width: 160px">
                <span class="kdv"> KDV %{{ product.kdv_oran }} {{ product.kdv_turu == 'dahil' ? 'Dahil' :'Hariç' }} </span>
                <span class="price d-block" :class="product.kdv_turu == 'haric' && 'text-info h5'">
                  {{ product.sabit_toplam.toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <span class="price" v-if="product.kdv_turu == 'haric'">
                  {{ product.sabit_toplam.genel_toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <span
                  class="p font-weight-light text-info"
                  style="font-size: 12px"
                  v-if="product.sabit_toplam.on_odeme_toplam > 0"
                >
                  Ön Ödeme Tutarı {{ product.sabit_toplam.on_odeme_toplam | exc(product.kur_turu) }}
                  {{ exhange.banka_kodu }}
                </span>
                <span
                  class="p font-weight-light text-info"
                  style="font-size: 12px"
                  v-if="product.sabit_toplam.on_odeme_toplam > 0"
                >
                  <b-form-checkbox v-model="product.odeme_turu" size="lg" :value="true" :unchecked-value="false">
                    Ön Ödeme Yap
                  </b-form-checkbox>
                </span>
                <b-button
                  squared
                  variant="outline-warning"
                  class="mt-1"
                  v-if="product.ekstra_hizmet_k_no.length"
                  @click="handlerEkstraHizmetler"
                >
                  Bu Ürün İçin Ekstra Hizmet Seç
                </b-button>
              </td>
              <td class="product-total">
                <b-button squared variant="outline-danger" @click="handlerRemove(index)"><i class="fad fa-trash"></i></b-button>
              </td>
            </tr>
            <tr v-if="product.ekstra_hizmet_sec && product.ekstra_hizmetler.length">
              <td colspan="5" class="bg-white">
                <table class="table table-sm table-hover w-100">
                  <tbody>
                    <SepetCartEkstraHizmetItem
                      v-for="(hizmet, index) in product.ekstra_hizmetler"
                      :hizmet="hizmet"
                      :product="product"
                      :rezervasyon="rezervasyon"
                      :tarih="product.rezervasyon_tarihi"
                      :key="index"
                    />
                    <tr>
                      <td colspan="4" class="font-weight-bold text-right h5 mb-0">
                        Ekstra Hizmet Toplam
                      </td>
                      <td class="font-weight-bold text-right h5 mb-0">
                        {{ toplamEkstraHizmetToplam | exc(sepet.sabit_kur) }} {{ exhange.banka_kodu }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </template> -->
        <template v-if="product.urun_tipi == 'tur'">
          <table class="table table-borderless table-striped p-0 m-0 w-100" :class="product.ekstra_hizmet_sec && 'border border-primary'">
            <tr>
              <td style="width: 110px; vertical-align: middle">
                <b-img
                  thumbnail
                  width="100"
                  :src="product.gorseller | sepetImage(product.urun_k_no, 'urun')"
                  :alt="product.baslik[defaultDil]"
                />
              </td>
              <td style="width: 280px">
                <table class="table table-sm table-borderless p-0 m-0">
                  <tr>
                    <td class="p-0"><i class="fad fa-box-full" /></td>
                    <td class="p-0">:</td>
                    <td class="p-0 font-weight-bolder">{{ product.baslik[defaultDil] }}</td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-ticket" v-b-tooltip.hover.top="'Bilet No'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.bilet_no }}</td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-stream" v-b-tooltip.hover.top="'Ürün Kodu'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.urun_kodu }}</td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-calendar" v-b-tooltip.hover.top="'Tarih'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.rezervasyon_tarihi.giris | moment }}</td>
                  </tr>
                  <tr v-if="product.seans_saati">
                    <td class="p-0"><i class="fad fa-clock" v-b-tooltip.hover.top="'Seans Saati'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.seans_saati }}</td>
                  </tr>
                  <tr v-if="product.bolge_alis_saati">
                    <td class="p-0"><i class="fad fa-clock" v-b-tooltip.hover.top="'Bölge Alış Saati'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.bolge_alis_saati }}</td>
                  </tr>
                </table>
              </td>
              <td class="p-0" style="width: 280px">
                <table class="table table-sm table-borderless p-0 m-0">
                  <tr v-for="(px, i) in product.pax" :key="i">
                    <td class="p-0 text-left" style="vertical-align: middle">{{ i }}</td>
                    <td class="p-0">
                      <b-form-group class="pt-1" label-for="pax">
                        <b-input-group size="sm">
                          <template #prepend>
                            <b-button squared size="sm" variant="danger" @click="handlerPaxChange(i, -1)"> - </b-button>
                          </template>
                          <b-form-input size="sm" class="text-center rounded-0" style="width: 40px" v-model="product.pax[i]" />
                          <template #append>
                            <b-button squared size="sm" variant="success" @click="handlerPaxChange(i, 1)"> + </b-button>
                          </template>
                        </b-input-group>
                      </b-form-group>
                    </td>
                    <td class="p-0 pl-2 text-right" style="vertical-align: middle">
                      <span v-if="px > 0">
                        {{ urun_toplam(px, kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat[i])) | exc(product.kur_turu) }}
                        {{ product.bugun_satis_fiyat[0].fiyat[i] > 0 ? exhange.banka_kodu : 'Ücretsiz' }}
                      </span>
                      <span v-else>Secilmedi</span>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="p-2 text-right" style="width: 200px">
                <span class="kdv"> KDV %{{ product.kdv_oran }} {{ product.kdv_turu == 'dahil' ? 'Dahil' : 'Hariç' }} </span>
                <span class="price d-block h5 font-weight-bold 1" :class="product.kdv_turu == 'haric' && 'text-info h6'">
                  {{ product.sabit_toplam.toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <span class="price h5 font-weight-bold" v-if="product.kdv_turu == 'haric'">
                  {{ product.sabit_toplam.genel_toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <b-button
                  squared
                  variant="outline-warning"
                  class="mt-1"
                  size="sm"
                  v-if="product.ekstra_hizmet_k_no.length"
                  @click="handlerEkstraHizmetler"
                >
                  Bu Ürün İçin Ekstra Hizmet Seç
                </b-button>
              </td>
              <td style="width: 60px; vertical-align: middle">
                <b-button squared variant="outline-danger" @click="handlerRemove(index)"><i class="fad fa-trash"></i></b-button>
              </td>
            </tr>
            <tr v-if="product.ekstra_hizmet_sec && product.ekstra_hizmetler.length">
              <td colspan="5" class="bg-white">
                <table class="table table-sm table-hover w-100">
                  <tbody>
                    <SepetCartEkstraHizmetItem
                      v-for="(hizmet, index) in product.ekstra_hizmetler"
                      :hizmet="hizmet"
                      :product="product"
                      :sepet="sepet"
                      :tarih="product.rezervasyon_tarihi"
                      :key="index"
                    />
                    <tr>
                      <td colspan="3" class="font-weight-bold text-right h5 mb-0">Ekstra Hizmet Toplam</td>
                      <td class="font-weight-bold text-right h5 mb-0">
                        {{ toplamEkstraHizmetToplam | exc(sepet.sabit_kur) }} {{ exhange.banka_kodu }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </template>
        <template v-if="product.urun_tipi == 'arac_kira'">
          <table class="table table-borderless table-striped p-0 m-0 w-100" :class="product.ekstra_hizmet_sec && 'border border-primary'">
            <tr>
              <td style="width: 110px; vertical-align: middle">
                <b-img
                  thumbnail
                  width="100"
                  :src="product.gorseller | sepetImage(product.urun_k_no, 'urun')"
                  :alt="product.baslik[defaultDil]"
                />
              </td>
              <td style="width: 280px">
                <table class="table table-sm table-borderless p-0 m-0">
                  <tr>
                    <td class="p-0"><i class="fad fa-box-full" /></td>
                    <td class="p-0">:</td>
                    <td class="p-0 font-weight-bolder">{{ product.baslik[defaultDil] }}</td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-ticket" v-b-tooltip.hover.top="'Bilet No'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.bilet_no }}</td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-stream" v-b-tooltip.hover.top="'Ürün Kodu'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.urun_kodu }}</td>
                  </tr>
                </table>
              </td>

              <td class="p-0" style="width: 280px">
                <table class="table table-sm table-borderless p-0 m-0">
                  <tr>
                    <td class="p-0 font-weight-bold">Alış Bölgesi</td>
                    <td class="p-0 pr-1">:</td>
                    <td class="p-0">{{ product.alis_bolgesi[defaultDil] }}</td>
                  </tr>
                  <tr>
                    <td class="p-0 font-weight-bold">Teslim Bölgesi</td>
                    <td class="p-0 pr-1">:</td>
                    <td class="p-0">{{ product.teslim_bolgesi[defaultDil] }}</td>
                  </tr>
                  <tr>
                    <td class="p-0 font-weight-bold">Alış Tarihi</td>
                    <td class="p-0 pr-1">:</td>
                    <td class="p-0">{{ product.rezervasyon_tarihi.giris | momentFull }}</td>
                  </tr>
                  <tr>
                    <td class="p-0 font-weight-bold">Teslim Tarihi</td>
                    <td class="p-0 pr-1">:</td>
                    <td class="p-0">{{ product.rezervasyon_tarihi.cikis | momentFull }}</td>
                  </tr>
                </table>
              </td>
              <td class="p-2 text-right" style="width: 200px">
                <span class="kdv"> KDV %{{ product.kdv_oran }} {{ product.kdv_turu == 'dahil' ? 'Dahil' : 'Hariç' }} </span>
                <span class="price d-block h5 font-weight-bold 1" :class="product.kdv_turu == 'haric' && 'text-info h6'">
                  {{ product.sabit_toplam.toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <span class="price h5 font-weight-bold" v-if="product.kdv_turu == 'haric'">
                  {{ product.sabit_toplam.genel_toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <b-button
                  squared
                  variant="outline-warning"
                  class="mt-1"
                  size="sm"
                  v-if="product.ekstra_hizmet_k_no.length"
                  @click="handlerEkstraHizmetler"
                >
                  Bu Ürün İçin Ekstra Hizmet Seç
                </b-button>
              </td>
              <td style="width: 60px; vertical-align: middle">
                <b-button squared variant="outline-danger" @click="handlerRemove(index)"><i class="fad fa-trash"></i></b-button>
              </td>
            </tr>
            <tr v-if="product.ekstra_hizmet_sec && product.ekstra_hizmetler.length">
              <td colspan="5" class="bg-white">
                <table class="table table-sm table-hover w-100">
                  <tbody>
                    <SepetCartEkstraHizmetItem
                      v-for="(hizmet, index) in product.ekstra_hizmetler"
                      :hizmet="hizmet"
                      :product="product"
                      :sepet="sepet"
                      :tarih="product.rezervasyon_tarihi"
                      :key="index"
                    />
                    <tr>
                      <td colspan="3" class="font-weight-bold text-right h5 mb-0">Ekstra Hizmet Toplam</td>
                      <td class="font-weight-bold text-right h5 mb-0">
                        {{ toplamEkstraHizmetToplam | exc(sepet.sabit_kur) }} {{ exhange.banka_kodu }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </template>

        <template v-if="product.urun_tipi == 'transfer'">
          <table class="table table-borderless table-striped p-0 m-0 w-100" :class="product.ekstra_hizmet_sec && 'border border-primary'">
            <tr>
              <td style="width: 110px; vertical-align: middle">
                <b-img thumbnail width="100" :src="product.gorseller | sepetImage('transfer')" :alt="product.baslik[defaultDil]" />
              </td>
              <td style="width: 280px">
                <table class="table table-sm table-borderless p-0 m-0">
                  <tr>
                    <td class="p-0"><i class="fad fa-box-full" /></td>
                    <td class="p-0">:</td>
                    <td class="p-0 font-weight-bolder">
                      {{ product.baslik[defaultDil] }} {{ product.pax.min }} / {{ product.pax.max }} Kişi
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-ticket" v-b-tooltip.hover.top="'Bilet No'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.bilet_no }}</td>
                  </tr>
                  <tr>
                    <td class="p-0"><i class="fad fa-calendar" v-b-tooltip.hover.top="'Tarih'"></i></td>
                    <td class="p-0">:</td>
                    <td class="p-0">{{ product.rezervasyon_tarihi.giris | momentText(defaultDil) }}</td>
                  </tr>
                  <tr>
                    <td class="p-0" v-if="product.nereden_type == 'havaalani' || product.nereye_type == 'havaalani'">
                      {{ product.saat_hesapla ? 'Uçuş Saati' : 'İniş Saati' }}
                    </td>
                    <td class="p-0" v-else>Alış Saati</td>
                    <td class="p-0">:</td>
                    <td class="p-0">
                      <validation-provider :rules="{ required: true }" v-slot="validationContext">
                        <b-form-input
                          class="rounded-0"
                          v-mask="'##:##'"
                          size="sm"
                          v-model="product.ucus_saat"
                          :state="getValidationState(validationContext)"
                          @input="handlerUcusSaatChange"
                        />
                      </validation-provider>
                    </td>
                  </tr>
                  <tr v-if="product.nereden_type == 'havaalani' || product.nereye_type == 'havaalani'">
                    <td class="p-0">Uçuş Bilet No</td>
                    <td class="p-0">:</td>
                    <td class="p-0">
                      <validation-provider :rules="{ required: true }" v-slot="validationContext">
                        <b-form-input
                          class="rounded-0"
                          size="sm"
                          placeholder="Uçuş Bilet No"
                          v-model="product.ucus_no"
                          :state="getValidationState(validationContext)"
                        />
                      </validation-provider>
                    </td>
                  </tr>
                </table>
              </td>

              <td class="p-0" style="width: 280px">
                <table class="table table-sm table-borderless p-0 m-0">
                  <tr>
                    <th rowspan="4" style="vertical-align: middle"><i class="fad fa-arrows-v fa-2x"></i></th>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">{{ product.nereden[defaultDil] }}</th>
                  </tr>
                  <tr>
                    <th class="font-weight-bold">{{ product.nereye[defaultDil] }}</th>
                  </tr>
                  <tr>
                    <th>Kişi Sayısı :{{ product.pax.yetiskin + product.pax.cocuk + product.pax.bebek }} Kişi</th>
                  </tr>
                </table>
              </td>
              <td class="p-2 text-right" style="width: 200px">
                <span class="kdv"> KDV %{{ product.kdv_oran }} {{ product.kdv_turu == 'dahil' ? 'Dahil' : 'Hariç' }} </span>
                <span class="price d-block h5 font-weight-bold" :class="product.kdv_turu == 'haric' && 'text-info h6'">
                  {{ product.sabit_toplam.toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <span class="price" v-if="product.kdv_turu == 'haric'">
                  {{ product.sabit_toplam.genel_toplam | exc(product.kur_turu) }} {{ exhange.banka_kodu }}
                </span>
                <b-button
                  squared
                  variant="outline-warning"
                  class="mt-1"
                  size="sm"
                  v-if="product.ekstra_hizmet_k_no.length"
                  @click="handlerEkstraHizmetler"
                >
                  Bu Ürün İçin Ekstra Hizmet Seç
                </b-button>
              </td>
              <td style="width: 60px; vertical-align: middle">
                <b-button squared variant="outline-danger" @click="handlerRemove(index)"><i class="fad fa-trash"></i></b-button>
              </td>
            </tr>
            <tr v-if="product.ekstra_hizmet_sec && product.ekstra_hizmetler.length">
              <td colspan="5" class="bg-white">
                <table class="table table-sm table-hover w-100">
                  <tbody>
                    <SepetCartEkstraHizmetItem
                      v-for="(hizmet, index) in product.ekstra_hizmetler"
                      :hizmet="hizmet"
                      :product="product"
                      :sepet="sepet"
                      :tarih="product.rezervasyon_tarihi"
                      :key="index"
                    />
                    <tr>
                      <td colspan="3" class="font-weight-bold text-right h5 mb-0">Ekstra Hizmet Toplam</td>
                      <td class="font-weight-bold text-right h5 mb-0">
                        {{ toplamEkstraHizmetToplam | exc(sepet.sabit_kur) }} {{ exhange.banka_kodu }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </template>
      </b-overlay>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import store from '@/store';
import Swal from 'sweetalert2';
import axiosIns from '@/libs/axios';
import { urun_toplam } from '@/libs/global';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import SepetCartEkstraHizmetItem from './SepetCartEkstraHizmetItem.vue';
import { ref, defineComponent, computed, toRefs } from '@vue/composition-api';
import { exChange } from '@/libs/global';
export default defineComponent({
  components: { SepetCartEkstraHizmetItem },
  props: {
    product: { type: Object, required: true },
    sepet: { type: Object, required: true },
    index: [Number],
  },
  setup(props) {
    const expo = {};
    const toast = useToast();
    const { router } = useRouter();
    const { product, sepet } = toRefs(props);
    expo.urun_toplam = ref(urun_toplam);
    expo.ekstra_open_close = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exhange = computed(() => store.getters.getExchange);
    expo.loaded = ref(false);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.handlerUcusSaatChange = (event) => {
      if (event.length >= 5) {
        const pro = product.value;
        const alis_tarih = moment(pro.rezervasyon_tarihi.giris).format('YYYY-MM-DD');
        var startTime = moment(event, 'HH:mm').format('HH:mm');
        if (pro.saat_hesapla) {
          var endTime = moment(pro.bolge_alis, 'HH:mm').format('HH:mm');

          const tarih = alis_tarih + ' ' + startTime;
          pro.rezervasyon_tarihi.giris = moment(tarih, 'YYYY-MM-DD HH:mm').subtract(endTime).format('YYYY-MM-DD HH:mm');
        } else {
          const tarih = alis_tarih + ' ' + startTime;
          pro.rezervasyon_tarihi.giris = moment(tarih, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
        }
      }
    };

    expo.kampanyaFiyatHesapla = (price) => {
      const kampanya = product.value.kampanya;
      let fiyat = 0;
      if (Object.keys(kampanya).length > 0) {
        if (kampanya.indirim_turu == 'yuzde') {
          const indirim = (price / 100) * kampanya.indirim_tutari;
          fiyat = price - indirim;
        } else {
          fiyat = price - kampanya.indirim_tutari;
        }
      } else {
        fiyat = price;
      }

      return fiyat;
    };

    expo.handlerPaxChange = (type, event) => {
      if (type == 'yetiskin') {
        product.value.pax[type] += event;
        if (product.value.pax[type] <= 0) {
          product.value.pax[type] = 1;
        }
      } else if (type == 'cocuk') {
        product.value.pax[type] += event;
        if (product.value.pax[type] < 0) {
          product.value.pax[type] = 0;
        }
      } else {
        product.value.pax[type] += event;
        if (product.value.pax[type] < 0) {
          product.value.pax[type] = 0;
        }
      }
    };

    expo.toplamEkstraHizmetToplam = computed(() => {
      const hizmet = product.value.ekstra_hizmetler;

      let toplam = 0;

      if (hizmet) {
        hizmet.forEach((el) => {
          toplam += el.toplam_fiyat;
        });
      }

      product.value.sabit_toplam.ekstra_toplam = toplam;
      if (sepet.value.sabit_kur != sepet.value.secilen_kur) {
        product.value.kur_toplam.ekstra_toplam = exChange(toplam, sepet.value.sabit_kur);
      }
      return toplam;
    });

    expo.handlerItemToplamHesapla = computed(() => {
      let yetiskin = 0,
        cocuk = 0,
        bebek = 0,
        toplam = 0;

      const fiyat = product.value.bugun_satis_fiyat[0].fiyat;
      const pax = product.value.pax;

      Object.keys(pax).forEach((el) => {
        if (el == 'yetiskin') yetiskin = urun_toplam(pax[el], expo.kampanyaFiyatHesapla(parseFloat(fiyat[el])));
        if (el == 'cocuk') cocuk = urun_toplam(pax[el], expo.kampanyaFiyatHesapla(parseFloat(fiyat[el])));
        if (el == 'bebek') bebek = urun_toplam(pax[el], expo.kampanyaFiyatHesapla(parseFloat(fiyat[el])));
      });

      toplam = yetiskin + cocuk + bebek;

      return toplam;
    });

    expo.handlerEkstraHizmetler = async () => {
      product.value.ekstra_hizmet_sec = !product.value.ekstra_hizmet_sec;
      expo.loaded.value = product.value.ekstra_hizmet_sec;

      const urun_ek_hizmet = await product.value.ekstra_hizmet_k_no;
      const urun_tipi = await product.value.urun_tipi;

      if (product.value.ekstra_hizmet_sec) {
        if (urun_ek_hizmet) {
          product.value.ekstra_hizmetler = [];
          axiosIns
            .get('/travel/rezervasyon-ekstra-hizmetler-get', { params: { statu: 1, urun_grubu: [urun_tipi], urun_ek_hizmet } })
            .then((res) => {
              if (res.data.success) {
                const hizmetler = res.data.data;
                urun_ek_hizmet.forEach((element) => {
                  const hiz = hizmetler.find((x) => x.k_no == element);
                  if (hiz != undefined) {
                    product.value.ekstra_hizmetler.push({
                      k_no: element,
                      check: false,
                      icerik: hiz.icerik,
                      hizmet_tipi: hiz.hizmet_tipi,
                      fiyat: hiz.fiyat,
                      gun: 0,
                      adet: 0,
                      gunler: [],
                      toplam_fiyat: 0,
                      kur_toplam_fiyat: 0,
                    });
                  }
                });
                expo.loaded.value = false;
              }
            });
        } else {
          expo.loaded.value = false;
        }
      } else {
        product.value.ekstra_hizmetler = [];
      }
    };

    expo.handlerRemove = (index) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayıt Silinecek !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.commit('SET_SEPET_DIREK_URUN_DELETE', index);
          toast.error('Ürün sepetten silindi.', {
            position: 'top-center',
          });
          if (!sepet.value.sepet_urunler.length) {
            store.commit('SET_SEPET_DIREK_REZERVASYON_RESET');
            router.push({ name: 'rezervasyon-urunler' });
          }
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
