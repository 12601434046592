<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold"><i class="fad fa-info-circle" /> Rezervasyon İnfo </b-card-header>
    <b-card-text>
      <b-img
        :src="product.gorseller | sepetImage(product.urun_k_no, 'urun')"
        :alt="product.baslik[defaultDil]"
        style="max-height: 200px; width: 100%"
      />
      <b-row>
        <b-col cols="12">
          <h6 class="text-center text-primary font-weight-bold">
            {{ product.rezervasyon_tarihi.giris | momentFull }} -
            {{ product.rezervasyon_tarihi.cikis | momentFull }}
          </h6>
          <div class="">
            <h6 class="p text-center font-weight-bold">" {{ product.baslik[defaultDil] }} " Rezervasyon Yapıyorsunuz.</h6>
          </div>

          <UrunRezervasyonBilgiYatVilla :form="product" />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12">
          <b-form-group label-cols="5" label="Promosyon Kodu">
            <b-input-group>
              <b-form-input
                size="sm"
                class="rounded-0"
                v-model="promosyon_kodu"
                placeholder="Promosyon Kodu"
                :disabled="product.promosyon_kodu != null"
              />
              <template #append>
                <b-button size="sm" squared variant="info" @click="handlerPromosyonSorgula"> <i class="fad fa-plus-square"></i></b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-cols="5" label="Uyruk">
            <v-select
              v-model="rezervasyon.lang"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!rezervasyon.k_no"
              placeholder="Uyruk"
              class="invoice-filter-select select-size-sm d-block"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-cols="5" label="Rezervasyon Durumu">
            <v-select
              v-model="rezervasyon.rezervasyon_durum"
              :options="rezervasyonDurumlari"
              :reduce="(rez) => rez.k_no"
              label="baslik"
              :clearable="false"
              :disabled="!rezervasyon.k_no"
              class="invoice-filter-select select-size-sm d-block"
              style="width: 100%"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-cols="5" label="Ödeme Tipi">
            <v-select
              v-model="rezervasyon.odeme_tipi"
              :options="odemeTipleri"
              :reduce="(odeme) => odeme.value"
              label="title"
              :clearable="false"
              :disabled="!rezervasyon.k_no"
              placeholder="Uyruk"
              class="invoice-filter-select select-size-sm d-block"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <template>
            <b-button squared type="submit" variant="success" block>
              (
              {{
                rezervasyon.odeme_tipi == 'on_odeme'
                  ? rezervasyon.rezervasyon_toplam.on_odeme_toplam
                  : rezervasyon.rezervasyon_toplam.ara_toplam | exc(rezervasyon.sabit_kur)
              }}
              {{ exchange.banka_kodu }} ) Rezervasyon Tamamla
            </b-button>
            <b-button-group class="w-100 pt-2">
              <b-button size="sm" squared variant="outline-danger" @click="handlerSepetiSil">
                <i class="fad fa-trash"></i> Sepeti Sil
              </b-button>
            </b-button-group>
          </template>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import Swal from 'sweetalert2';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import UrunRezervasyonBilgiYatVilla from './UrunRezervasyonBilgiYatVilla.vue';
import { ref, computed, defineComponent, toRefs } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: { vSelect, UrunRezervasyonBilgiYatVilla },
  props: {
    rezervasyon: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { rezervasyon } = toRefs(props);
    const { router } = useRouter();
    const toast = useToast();
    expo.exchange = computed(() => store.getters.getExchange);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    // expo.rezervasyon = computed(() => store.getters.getSorSatRezervasyon);
    expo.rezervasyonDurumlari = computed(() => store.getters.getRezervasyonDurumlari);
    expo.product = computed(() => rezervasyon.value.sepet_urunler[0]);
    expo.promosyon_kodu = ref(null);

    expo.odemeTipleri = ref([
      {
        value: 'on_odeme',
        title: 'Ön Ödemeli',
      },
      {
        value: 'hepsi',
        title: 'Hepsi',
      },
    ]);

    expo.handlerPromosyonSorgula = () => {
      expo.loaded.value = true;
      if (expo.promosyon_kodu.value != null) {
        axiosIns.post('/travel/rezervasyon-promosyon-sorgula-post', { kod: expo.promosyon_kodu.value }).then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            expo.rezervasyon.value.promosyon_kodu = expo.promosyon_kodu.value;

            const toplam = expo.rezervasyon.value.rezervasyon_toplam.urun_toplam;
            const indirim = data.indirim_tutari;

            if (data.indirim_turu == 'yuzde') {
              const kupon_indirim = (toplam / 100) * indirim;
              expo.rezervasyon.value.rezervasyon_toplam.kupon_indirim = kupon_indirim;
              expo.rezervasyon.value.rezervasyon_toplam.ara_toplam = toplam - kupon_indirim;
            } else {
              const kupon_indirim = toplam - indirim;
              expo.rezervasyon.value.rezervasyon_toplam.kupon_indirim = kupon_indirim;
              expo.rezervasyon.value.rezervasyon_toplam.ara_toplam = toplam - kupon_indirim;
            }
            expo.promosyon_kodu.value = null;
          }
          expo.loaded.value = false;
        });
      } else {
        toast.error('Lütfen geçerli bir kod giriniz.', {
          position: 'bottom-left',
        });
        expo.loaded.value = false;
      }
    };

    expo.handlerSepetiSil = () => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayıt Silinecek !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.commit('SET_SEPET_SORSAT_REZERVASYON_RESET');
          toast.error('Sepet Silindi', {
            position: 'bottom-left',
          });
          router.push({ name: 'rezervasyon-urunler' });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
