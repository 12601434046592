<template>
  <aside class="sticky-sidebar-wrapper h-100" sticky-container>
    <b-card no-body class="rounded-0">
      <b-overlay :show="loaded" spinner-variant="primary" spinner-type="grow">
        <b-card-header class="bg-white font-weight-bold"> <i class="fad fa-info-circle" /> Sepet İnfo</b-card-header>
        <b-card-text class="p-3">
          <b-row>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom">
                <h6 class="font-weight-bold">Ürün Toplam :</h6>
                <h6 class="text-primary font-weight-bold">
                  {{ rezervasyon.rezervasyon_toplam.urun_toplam | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
              </div>
              <div
                class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom"
                v-if="rezervasyon.rezervasyon_toplam.ekstra_toplam > 0"
              >
                <h6 class="font-weight-bold">Ekstra Toplam :</h6>
                <h6 class="text-primary font-weight-bold">
                  {{ rezervasyon.rezervasyon_toplam.ekstra_toplam | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
              </div>
              <div
                class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom"
                v-if="rezervasyon.rezervasyon_toplam.kupon_indirim > 0"
              >
                <h6 class="font-weight-bold">
                  İndirim Toplam :
                  <b-link class="btn btn-sm btn-danger" @click="handlerPromosyonSil"><i class="fad fa-trash"></i></b-link>
                </h6>
                <h6 class="text-primary font-weight-bold">
                  {{ rezervasyon.rezervasyon_toplam.kupon_indirim | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
              </div>
              <div
                class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom"
                v-if="rezervasyon.rezervasyon_toplam.iskonto_indirim > 0 && rezervasyon.rezervasyon_toplam.ara_toplam > 0"
              >
                <h6 class="font-weight-bold">
                  İskonto Toplam :
                  <b-link class="btn btn-sm btn-danger" @click="handlerIskontoSil"><i class="fad fa-trash"></i></b-link>
                </h6>
                <h6 class="text-primary font-weight-bold">
                  {{ rezervasyon.rezervasyon_toplam.iskonto_indirim | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
              </div>

              <div
                class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom"
                v-if="rezervasyon.rezervasyon_toplam.kupon_indirim > 0 && rezervasyon.rezervasyon_toplam.ara_toplam > 0"
              >
                <h6 class="font-weight-bold">Ara Toplam :</h6>
                <h6 class="text-primary font-weight-bold">
                  {{ rezervasyon.rezervasyon_toplam.ara_toplam | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
              </div>

              <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom">
                <h6 class="font-weight-bold">Kdv Toplam :</h6>
                <h6 class="text-primary font-weight-bold" v-if="rezervasyon.rezervasyon_toplam.kdv_toplam > 0">
                  {{ rezervasyon.rezervasyon_toplam.kdv_toplam | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
                <h6 class="text-primary font-weight-bold" v-else>Tüm Vergiler Dahil</h6>
              </div>
              <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom">
                <h6 class="font-weight-bold">Genel Toplam :</h6>
                <h6 class="text-primary font-weight-bold">
                  {{ rezervasyon.rezervasyon_toplam.genel_toplam | exc(rezervasyon.sabit_kur) }} {{ exchange.banka_kodu }}
                </h6>
              </div>
            </b-col>
            <b-col cols="12">
              <b-form @submit.stop.prevent="handlerPromosyonSorgula">
                <b-form-group label-cols="5" label="Promosyon Kodu">
                  <b-input-group>
                    <b-form-input
                      size="sm"
                      class="rounded-0"
                      v-model="promosyon_kodu"
                      placeholder="Promosyon Kodu"
                      :disabled="rezervasyon.promosyon_kodu != null"
                    />
                    <template #append>
                      <b-button type="submit" size="sm" squared variant="info">
                        <i class="fad fa-plus-square" />
                      </b-button>
                    </template>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <b-form @submit.stop.prevent="handlerIskontoUygula">
                <b-form-group label-cols="5" label="Özel İskonto">
                  <b-input-group>
                    <b-form-input size="sm" class="rounded-0 text-right" v-model="iskonto_indirim" placeholder="Özel İskonto" />
                    <template #append>
                      <b-button type="submit" size="sm" squared variant="info">
                        <i class="fad fa-plus-square" />
                      </b-button>
                    </template>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12">
              <b-form-group label-cols="5" label="Uyruk">
                <v-select
                  v-model="rezervasyon.lang"
                  :options="diller"
                  :reduce="(diller) => diller.lang"
                  label="title"
                  :clearable="false"
                  :disabled="!rezervasyon.k_no"
                  placeholder="Uyruk"
                  class="invoice-filter-select select-size-sm d-block"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="rezervasyonDurumlari.length > 0">
              <b-form-group label-cols="5" label="Rezervasyon Durumu">
                <v-select
                  v-model="rezervasyon.rezervasyon_durum"
                  :options="rezervasyonDurumlari"
                  :reduce="(rez) => rez.k_no"
                  label="baslik"
                  :clearable="false"
                  :disabled="!rezervasyon.k_no"
                  class="invoice-filter-select select-size-sm d-block"
                  style="width: 100%"
                >
                  <template slot="selected-option" slot-scope="option">
                    {{ option.icerik[defaultDil].baslik }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.icerik[defaultDil].baslik }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <template>
                <b-button squared type="submit" variant="success" block>
                  (
                  {{
                    rezervasyon.rezervasyon_toplam.tahsilat_toplam > 0
                      ? rezervasyon.rezervasyon_toplam.tahsilat_toplam
                      : rezervasyon.rezervasyon_toplam.genel_toplam | exc(rezervasyon.sabit_kur)
                  }}
                  {{ exchange.banka_kodu }} ) Rezervasyon Tamamla
                </b-button>
                <b-button-group class="w-100 pt-2">
                  <b-button size="sm" squared variant="outline-danger" @click="handlerSepetiSil">
                    <i class="fad fa-trash"></i> Sepeti Sil
                  </b-button>
                </b-button-group>
              </template>
            </b-col>
          </b-row>
        </b-card-text>
      </b-overlay>
    </b-card>
  </aside>
</template>

<script>
import moment from 'moment';
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import axiosIns from '@/libs/axios';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import { urun_toplam, kdv_hesapla, exChange } from '@/libs/global';
import { ref, computed, defineComponent, toRefs, watch } from '@vue/composition-api';

export default defineComponent({
  components: { vSelect },
  props: {
    rezervasyon: { type: Object, required: true },
  },
  setup(props) {
    const expo = {};
    const toast = useToast();
    const { router } = useRouter();
    const { rezervasyon } = toRefs(props);
    expo.exchange = computed(() => store.getters.getExchange);
    expo.loaded = ref(false);
    expo.promosyon_kodu = ref(null);
    expo.iskonto_indirim = ref(0);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.rezervasyonDurumlari = computed(() => store.getters.getRezervasyonDurumlari);
    expo.direkRezervasyon = computed(() => store.getters.getDirekRezervasyon);
    expo.genelKampanya = computed(() => store.getters.getKampanyaAyar);

    expo.promosyonMusaitmi = computed(() => {
      if (rezervasyon.value.k_no) {
        return true;
      } else {
        return false;
      }
    });

    expo.handlerPromosyonSorgula = () => {
      expo.loaded.value = true;
      axiosIns.post('/travel/rezervasyon-promosyon-sorgula-post', { kod: expo.promosyon_kodu.value }).then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          rezervasyon.value.promosyon_kodu = expo.promosyon_kodu.value;

          const toplam = rezervasyon.value.rezervasyon_toplam.urun_toplam;
          const indirim = data.indirim_tutari;

          if (data.indirim_turu == 'yuzde') {
            const kupon_indirim = (toplam / 100) * indirim;
            rezervasyon.value.rezervasyon_toplam.kupon_indirim = kupon_indirim;
            rezervasyon.value.rezervasyon_toplam.ara_toplam = toplam - kupon_indirim;
          } else {
            const kupon_indirim = toplam - indirim;
            rezervasyon.value.rezervasyon_toplam.kupon_indirim = kupon_indirim;
            rezervasyon.value.rezervasyon_toplam.ara_toplam = toplam - kupon_indirim;
          }
          expo.promosyon_kodu.value = null;
        }
        expo.loaded.value = false;
      });
    };

    expo.handlerPromosyonSil = () => {
      rezervasyon.value.promosyon_kodu = null;
      rezervasyon.value.rezervasyon_toplam.kupon_indirim = 0;
    };

    expo.handlerIskontoUygula = () => {
      const urun_toplam = rezervasyon.value.rezervasyon_toplam.urun_toplam;
      if (parseFloat(expo.iskonto_indirim.value) > parseFloat(urun_toplam)) {
        toast.error('İstkonto toplam sepet toplamından fazla olamaz.', {
          position: 'bottom-left',
        });
      } else {
        rezervasyon.value.rezervasyon_toplam.iskonto_indirim = expo.iskonto_indirim.value;
        store.commit('SET_DIREK_REZERVASYON', rezervasyon.value);
      }
    };

    expo.handlerIskontoSil = () => {
      rezervasyon.value.rezervasyon_toplam.iskonto_indirim = 0;
      expo.iskonto_indirim.value = 0;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.handlerSepetiSil = () => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayıt Silinecek !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.commit('SET_SEPET_DIREK_REZERVASYON_RESET');
          toast.error('Sepet Silindi', {
            position: 'bottom-left',
          });
          router.push({ name: 'rezervasyon-urunler' });
        }
      });
    };

    const sepetBazliKampanyaHesapla = (kampanya, price) => {
      let fiyat = 0,
        indirim = 0;
      const pro = expo.direkRezervasyon.value.sepet_urunler;

      if (kampanya.kampanya_turu == 'adet') {
        if (pro.length >= parseInt(kampanya.toplam_tutar)) {
          if (kampanya.indirim_turu == 'yuzde') {
            indirim = (price / 100) * kampanya.indirim_tutari;
            fiyat = price - indirim;
          } else {
            fiyat = price - kampanya.indirim_tutari;
          }
        }
      } else {
        if (price > kampanya.toplam_tutar) {
          if (kampanya.indirim_turu == 'yuzde') {
            indirim = (price / 100) * kampanya.indirim_tutari;
            fiyat = price - indirim;
          } else {
            fiyat = price - kampanya.indirim_tutari;
          }
        }
      }

      return { fiyat, indirim };
    };

    const urunBazliKampanyaFiyatHesapla = (kampanya, price) => {
      let fiyat = 0;
      if (Object.keys(kampanya).length > 0) {
        if (kampanya.indirim_turu == 'yuzde') {
          const indirim = (price / 100) * kampanya.indirim_tutari;
          fiyat = price - indirim;
        } else {
          fiyat = price - kampanya.indirim_tutari;
        }
      } else {
        fiyat = price;
      }

      return fiyat;
    };

    const handlerItemToplamHesapla = (product, index) => {
      let yetiskin_fiyat = 0,
        cocuk_fiyat = 0,
        bebek_fiyat = 0,
        yetiskin = 0,
        cocuk = 0,
        bebek = 0,
        toplam = 0;

      const kampanya = product.kampanya;

      if (product.urun_tipi == 'tur') {
        const fiyat = product.bugun_satis_fiyat[0].fiyat;
        const pax = product.pax;

        if (product.fiyatlandirma == 'tekil') {
          Object.keys(pax).forEach((el) => {
            if (el == 'yetiskin') {
              yetiskin_fiyat = parseFloat(fiyat[el]);
              yetiskin = urun_toplam(pax[el], urunBazliKampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
            }
            if (el == 'cocuk') {
              cocuk_fiyat = parseFloat(fiyat[el]);
              cocuk = urun_toplam(pax[el], urunBazliKampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
            }
            if (el == 'bebek') {
              bebek_fiyat = parseFloat(fiyat[el]);
              bebek = urun_toplam(pax[el], urunBazliKampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
            }
          });
        } else {
          yetiskin_fiyat = parseFloat(fiyat.fiyat);
          cocuk_fiyat = parseFloat(fiyat.arti_kisi);
          yetiskin = urunBazliKampanyaFiyatHesapla(kampanya, yetiskin_fiyat);

          const pax_total = pax.yetiskin + pax.cocuk;

          if (pax_total > parseInt(fiyat.en_cok)) {
            const arti_kisi = pax_total - parseInt(fiyat.en_cok);
            cocuk = parseFloat(urun_toplam(arti_kisi, urunBazliKampanyaFiyatHesapla(kampanya, cocuk_fiyat)));
          }
        }

        toplam = yetiskin + cocuk + bebek;
      } else if (
        product.urun_tipi == 'arac_kira' ||
        product.urun_tipi == 'villa' ||
        product.urun_tipi == 'yat' ||
        product.urun_tipi == 'transfer'
      ) {
        toplam = product.sabit_toplam.toplam;
      }

      const kdv_toplam = product.kdv_turu == 'haric' ? kdv_hesapla(toplam, product.kdv_oran) : 0;

      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.yetiskin_fiyat = yetiskin_fiyat;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.cocuk_fiyat = cocuk_fiyat;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.bebek_fiyat = bebek_fiyat;

      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.yetiskin = yetiskin;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.cocuk = cocuk;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.bebek = bebek;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.toplam = toplam;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.kdv_oran = product.kdv_oran;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.kdv_toplam = kdv_toplam;
      expo.direkRezervasyon.value.sepet_urunler[index].sabit_toplam.genel_toplam = toplam + kdv_toplam;

      if (expo.direkRezervasyon.value.secilen_kur != expo.direkRezervasyon.value.sabit_kur) {
        const kur = expo.direkRezervasyon.value.sabit_kur;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin_fiyat = exChange(yetiskin_fiyat, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.cocuk_fiyat = exChange(cocuk_fiyat, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.bebek_fiyat = exChange(bebek_fiyat, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin = exChange(yetiskin, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.cocuk = exChange(cocuk, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.bebek = exChange(bebek, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.toplam = exChange(toplam, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.kdv_oran = product.kdv_oran;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.kdv_toplam = exChange(kdv_toplam, kur);
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.genel_toplam = exChange(toplam + kdv_toplam, kur);
      } else {
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin_fiyat = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.cocuk_fiyat = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.bebek_fiyat = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.cocuk = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.bebek = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.toplam = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.kdv_oran = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.kdv_toplam = 0;
        expo.direkRezervasyon.value.sepet_urunler[index].kur_toplam.genel_toplam = 0;
      }

      return toplam;
    };

    const sepetToplamHesapla = () => {
      let urun_toplam = 0,
        ekstra_toplam = 0,
        kupon_indirim = expo.direkRezervasyon.value.rezervasyon_toplam.kupon_indirim,
        sepet_indirim = 0,
        kdv_toplam = 0,
        taksit_fark = 0,
        ara_toplam = 0,
        genel_toplam = 0;

      const pro = expo.direkRezervasyon.value.sepet_urunler;

      // const genelKampanya = store.getters.getKampanyaAyar;

      pro.forEach((el, index) => {
        const urun_item_toplam = handlerItemToplamHesapla(el, index);

        kdv_toplam += el.kdv_turu == 'haric' ? kdv_hesapla(urun_item_toplam, el.kdv_oran) : 0;
        urun_toplam += urun_item_toplam;
        if (el.ekstra_hizmetler.length > 0) {
          ekstra_toplam += el.sabit_toplam.ekstra_toplam;
        }
      });

      ara_toplam = urun_toplam + ekstra_toplam - kupon_indirim;

      if (expo.genelKampanya.value != undefined) {
        const kampIndirim = sepetBazliKampanyaHesapla(expo.genelKampanya.value, ara_toplam);
        if (kampIndirim.fiyat != 0 && kampIndirim.indirim != 0) {
          ara_toplam = kampIndirim.fiyat;
          sepet_indirim = kampIndirim.indirim;
        }
      }

      genel_toplam = ara_toplam + kdv_toplam;

      expo.direkRezervasyon.value.rezervasyon_toplam.urun_toplam = urun_toplam;
      expo.direkRezervasyon.value.rezervasyon_toplam.ekstra_toplam = ekstra_toplam;
      expo.direkRezervasyon.value.rezervasyon_toplam.sepet_indirim = sepet_indirim;
      expo.direkRezervasyon.value.rezervasyon_toplam.kdv_toplam = kdv_toplam;
      expo.direkRezervasyon.value.rezervasyon_toplam.ara_toplam = ara_toplam;
      expo.direkRezervasyon.value.rezervasyon_toplam.genel_toplam = genel_toplam;

      if (expo.direkRezervasyon.value.secilen_kur != expo.direkRezervasyon.value.sabit_kur) {
        const kur = expo.direkRezervasyon.value.sabit_kur;
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.urun_toplam = exChange(urun_toplam, kur);
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.ekstra_toplam = exChange(ekstra_toplam, kur);
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.sepet_indirim = exChange(sepet_indirim, kur);
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.kdv_toplam = exChange(kdv_toplam, kur);
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.ara_toplam = exChange(ara_toplam, kur);
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.genel_toplam = exChange(genel_toplam, kur);
      } else {
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.urun_toplam = 0;
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.ekstra_toplam = 0;
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.sepet_indirim = 0;
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.kdv_toplam = 0;
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.ara_toplam = 0;
        expo.direkRezervasyon.value.kur_rezervasyon_toplam.genel_toplam = 0;
      }
    };

    // const kampanyaFiyatHesapla = (kampanya, price) => {
    //   let fiyat = 0;
    //   if (Object.keys(kampanya).length > 0) {
    //     if (kampanya.indirim_turu == 'yuzde') {
    //       const indirim = (price / 100) * kampanya.indirim_tutari;
    //       fiyat = price - indirim;
    //     } else {
    //       fiyat = price - kampanya.indirim_tutari;
    //     }
    //   } else {
    //     fiyat = price;
    //   }

    //   return fiyat;
    // };

    // const handlerItemToplamHesapla = (product, index, odeme_yontemi) => {
    //   let yetiskin_fiyat = 0,
    //     cocuk_fiyat = 0,
    //     bebek_fiyat = 0,
    //     yetiskin = 0,
    //     cocuk = 0,
    //     bebek = 0,
    //     toplam = 0,
    //     deposito = 0,
    //     ekstra_toplam = product.sabit_toplam.ekstra_toplam,
    //     genel_toplam = 0;

    //   const kampanya = product.kampanya;

    //   if (product.urun_tipi == 'tur') {
    //     const fiyat = product.bugun_satis_fiyat[0].fiyat;
    //     const pax = product.pax;

    //     Object.keys(pax).forEach((el) => {
    //       bebek_fiyat = pax[el];
    //       if (el == 'yetiskin') {
    //         yetiskin_fiyat = parseFloat(fiyat[el]);
    //         yetiskin = urun_toplam(pax[el], kampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
    //       }
    //       if (el == 'cocuk') {
    //         cocuk_fiyat = parseFloat(fiyat[el]);
    //         cocuk = urun_toplam(pax[el], kampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
    //       }
    //       if (el == 'bebek') {
    //         bebek_fiyat = parseFloat(fiyat[el]);
    //         bebek = urun_toplam(pax[el], kampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
    //       }
    //     });

    //     toplam = yetiskin + cocuk + bebek;
    //   } else if (
    //     product.urun_tipi == 'arac_kira' ||
    //     product.urun_tipi == 'villa' ||
    //     product.urun_tipi == 'yat' ||
    //     product.urun_tipi == 'transfer'
    //   ) {
    //     toplam = product.sabit_toplam.toplam;
    //   }

    //   const kdv_toplam = product.kdv_turu == 'haric' ? kdv_hesapla(toplam, product.kdv_oran) : 0;

    //   genel_toplam = toplam + kdv_toplam + ekstra_toplam;

    //   if (odeme_yontemi == 'kredi_karti') {
    //     deposito = genel_toplam;
    //   }

    //   rezervasyon.value.secilen_kur = expo.exchange.value.banka_kodu;
    //   rezervasyon.value.secilen_kur_fiyat = expo.exchange.value.satis;

    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.yetiskin_fiyat = yetiskin_fiyat;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.cocuk_fiyat = cocuk_fiyat;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.bebek_fiyat = bebek_fiyat;

    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.yetiskin = yetiskin;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.cocuk = cocuk;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.bebek = bebek;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.toplam = toplam;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.kdv_oran = product.kdv_oran;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.kdv_toplam = kdv_toplam;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.deposito = deposito;
    //   rezervasyon.value.sepet_urunler[index].sabit_toplam.genel_toplam = genel_toplam;

    //   if (rezervasyon.value.secilen_kur != rezervasyon.value.sabit_kur) {
    //     const kur = rezervasyon.value.sabit_kur;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin_fiyat = exChange(yetiskin_fiyat, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.cocuk_fiyat = exChange(cocuk_fiyat, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.bebek_fiyat = exChange(bebek_fiyat, kur);

    //     rezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin = exChange(yetiskin, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.cocuk = exChange(cocuk, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.bebek = exChange(bebek, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.toplam = exChange(toplam, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_oran = product.kdv_oran;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_toplam = exChange(kdv_toplam, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.deposito = exChange(deposito, kur);
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.genel_toplam = exChange(genel_toplam, kur);
    //   } else {
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin_fiyat = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.cocuk_fiyat = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.bebek_fiyat = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.cocuk = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.bebek = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.toplam = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_oran = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_toplam = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.deposito = 0;
    //     rezervasyon.value.sepet_urunler[index].kur_toplam.genel_toplam = 0;
    //   }
    //   return toplam;
    // };

    // const sepetToplamHesapla = () => {
    //   let urun_toplam = 0,
    //     ekstra_toplam = 0,
    //     kupon_indirim = rezervasyon.value.rezervasyon_toplam.kupon_indirim,
    //     iskonto_indirim = rezervasyon.value.rezervasyon_toplam.iskonto_indirim,
    //     kdv_toplam = 0,
    //     taksit_fark = 0,
    //     ara_toplam = 0,
    //     genel_toplam = 0;

    //   const pro = rezervasyon.value.sepet_urunler;

    //   pro.forEach((el, index) => {
    //     const urun_item_toplam = handlerItemToplamHesapla(el, index, rezervasyon.value.odeme_yontemi);
    //     kdv_toplam += el.kdv_turu == 'haric' ? kdv_hesapla(urun_item_toplam, el.kdv_oran) : 0;
    //     urun_toplam += urun_item_toplam;
    //     if (el.ekstra_hizmetler.length > 0) {
    //       ekstra_toplam += el.sabit_toplam.ekstra_toplam;
    //     }

    //     // if (el.odeme_turu && el.sabit_toplam.on_odeme_toplam != undefined) {
    //     //   tahsilat_toplam += el.sabit_toplam.on_odeme_toplam;
    //     // } else {
    //     //   tahsilat_toplam += el.sabit_toplam.genel_toplam;
    //     // }
    //   });

    //   ara_toplam = urun_toplam + ekstra_toplam - (kupon_indirim + iskonto_indirim);
    //   genel_toplam = ara_toplam + kdv_toplam;

    //   rezervasyon.value.rezervasyon_toplam.urun_toplam = urun_toplam;
    //   rezervasyon.value.rezervasyon_toplam.ekstra_toplam = ekstra_toplam;
    //   rezervasyon.value.rezervasyon_toplam.kupon_indirim = kupon_indirim;
    //   rezervasyon.value.rezervasyon_toplam.iskonto_indirim = iskonto_indirim;
    //   rezervasyon.value.rezervasyon_toplam.kdv_toplam = kdv_toplam;
    //   rezervasyon.value.rezervasyon_toplam.ara_toplam = ara_toplam;
    //   rezervasyon.value.rezervasyon_toplam.genel_toplam = genel_toplam;

    //   if (rezervasyon.value.secilen_kur != rezervasyon.value.sabit_kur) {
    //     const kur = rezervasyon.value.sabit_kur;
    //     rezervasyon.value.kur_rezervasyon_toplam.urun_toplam = exChange(urun_toplam, kur);
    //     rezervasyon.value.kur_rezervasyon_toplam.ekstra_toplam = exChange(ekstra_toplam, kur);
    //     rezervasyon.value.kur_rezervasyon_toplam.kupon_indirim = exChange(kupon_indirim, kur);
    //     rezervasyon.value.kur_rezervasyon_toplam.iskonto_indirim = exChange(iskonto_indirim, kur);
    //     rezervasyon.value.kur_rezervasyon_toplam.kdv_toplam = exChange(kdv_toplam, kur);
    //     rezervasyon.value.kur_rezervasyon_toplam.ara_toplam = exChange(ara_toplam, kur);
    //     rezervasyon.value.kur_rezervasyon_toplam.genel_toplam = exChange(genel_toplam, kur);
    //   } else {
    //     rezervasyon.value.kur_rezervasyon_toplam.urun_toplam = 0;
    //     rezervasyon.value.kur_rezervasyon_toplam.ekstra_toplam = 0;
    //     rezervasyon.value.kur_rezervasyon_toplam.kupon_indirim = 0;
    //     rezervasyon.value.kur_rezervasyon_toplam.iskonto_indirim = 0;
    //     rezervasyon.value.kur_rezervasyon_toplam.kdv_toplam = 0;
    //     rezervasyon.value.kur_rezervasyon_toplam.ara_toplam = 0;
    //     rezervasyon.value.kur_rezervasyon_toplam.genel_toplam = 0;
    //   }
    // };

    watch(
      rezervasyon,
      (val) => {
        sepetToplamHesapla();
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
