<template>
  <div>
    <template v-if="sepet.k_no && sepet.sepet_urunler.length">
      <b-overlay :show="show" spinner-variant="primary" spinner-type="grow">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12" md="8">
                <MisafirBilgileri :sepet="sepet" />
                <RezervasyonNotu class="mt-3" :sepet="sepet" />
                <UrunDigerMisafirBilgisi class="mt-3" :sepet="sepet" />
                <UrunEkstraHizmetBilgileri :sepet="sepet" />
                <OdemeBilgisi class="mt-3" :sepet="sepet" />
              </b-col>
              <b-col cols="12" md="4">
                <UrunSorgulaOzet :rezervasyon="sepet" />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <b-alert :show="true" variant="warning" class="text-center">
            <h1>UYARI !!</h1>
            <p>Sepette Gösterilecek Ürün Bulunmamaktadır</p>
            <router-link to="/rezervasyon/urunler" class="text-body"> Yeni Ürün Eklemek için Tıkla</router-link>
          </b-alert>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { ref, computed, defineComponent, watch, onMounted } from '@vue/composition-api';
import MisafirBilgileri from './component/sepet/MisafirBilgileri.vue';
import RezervasyonNotu from './component/sepet/RezervasyonNotu.vue';
import OdemeBilgisi from './component/sepet/OdemeBilgisi.vue';
import { useRouter } from '@/libs/utils';
import axiosIns from '@/libs/axios';
import UrunSorgulaOzet from './component/urunler/UrunSorgulaOzet.vue';
import UrunEkstraHizmetBilgileri from './component/urunler/UrunEkstraHizmetBilgileri.vue';
import UrunDigerMisafirBilgisi from './component/urunler/UrunDigerMisafirBilgisi.vue';
export default defineComponent({
  components: {
    MisafirBilgileri,
    RezervasyonNotu,
    OdemeBilgisi,
    UrunSorgulaOzet,
    UrunDigerMisafirBilgisi,
    UrunEkstraHizmetBilgileri,
  },
  setup(_, context) {
    const expo = {};
    const { router } = useRouter();
    const toast = useToast();
    expo.show = ref(false);
    expo.sepet = computed(() => store.getters.getSorSatRezervasyon);

    expo.onSubmit = () => {
      context.emit('show', true);

      expo.sepet.value.satis_yontemi = 'direk';
      expo.sepet.value.modul = 'yonetim';

      axiosIns.post('/travel/rezervasyon-ekle', expo.sepet.value).then((res) => {
        if (res.data.success) {
          const data = res.data;
          if (data.payment == 'kredi_karti' && data.threeDSHtmlContent) {
            document.documentElement.innerHTML = data.threeDSHtmlContent;
            document.getElementsByTagName('form')[0].submit();
          } else {
            toast.success('Yeni Rezervasyon Ekleme Başarılı.', { position: 'bottom-left' });
            store.commit('SET_SEPET_SORSAT_REZERVASYON_RESET');
            router.push({ name: 'rezervasyon-urunler' });
          }

          context.emit('show', false);
        } else {
          toast.warning(res.data.message);
          context.emit('show', false);
        }
      });
    };

    const handlerEkstraHizmetler = async () => {
      if (expo.sepet.value.k_no) {
        context.emit('show', true);
        const urun_ek_hizmet = await expo.sepet.value.sepet_urunler[0].ekstra_hizmet_k_no;
        const urun_tipi = await expo.sepet.value.sepet_urunler[0].urun_tipi;

        if (urun_ek_hizmet) {
          expo.sepet.value.sepet_urunler[0].ekstra_hizmetler = [];
          axiosIns
            .get('/travel/rezervasyon-ekstra-hizmetler-get', { params: { statu: 1, urun_grubu: [urun_tipi], urun_ek_hizmet } })
            .then((res) => {
              if (res.data.success) {
                const hizmetler = res.data.data;
                urun_ek_hizmet.forEach((element) => {
                  const hiz = hizmetler.find((x) => x.k_no == element);
                  if (hiz != undefined) {
                    expo.sepet.value.sepet_urunler[0].ekstra_hizmetler.push({
                      k_no: element,
                      check: false,
                      icerik: hiz.icerik,
                      hizmet_tipi: hiz.hizmet_tipi,
                      fiyat: hiz.fiyat,
                      gun: 0,
                      adet: 0,
                      gunler: [],
                      toplam_fiyat: 0,
                      kur_toplam_fiyat: 0,
                    });
                  }
                });
                context.emit('show', false);
              }
            });
        } else {
          context.emit('show', false);
        }
      }
    };

    onMounted(() => {
      handlerEkstraHizmetler();
    });

    // watch(
    //   expo.sepet,
    //   (val) => {
    //     handlerEkstraHizmetler();
    //   },
    //   { immediate: true }
    // );
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
