<template>
  <div>
    <b-overlay :show="loaded" rounded opacity="0.6" spinner spinner-variant="primary" class="d-inline-block w-100">
      <div class="d-flex justify-content-between align-items-center border-bottom pt-1">
        <span class="text-primary">Rezervasyon No :</span>
        <span class="text-primary font-weight-bold">{{ rezervasyon.k_no }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center py-1 border-bottom">
        <span>Kişi Sayısı:</span>
        <span class="text-primary font-weight-bold">{{ form.pax.yetiskin + form.pax.cocuk + form.pax.bebek }} Kişi</span>
      </div>
      <div class="d-flex justify-content-between align-items-center py-1 border-bottom">
        <span>Konaklama Süresi :</span>
        <span class="text-primary font-weight-bold">{{ form.toplam_gun }} Gece</span>
      </div>
      <div class="d-flex justify-content-between align-items-center py-1 border-bottom">
        <span>Ürün Toplam :</span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.urun_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center py-1 border-bottom" v-if="rezervasyon.rezervasyon_toplam.ekstra_toplam">
        <span>Ekstra Hizmet Toplam :</span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.ekstra_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center py-1 border-bottom" v-if="rezervasyon.rezervasyon_toplam.kupon_indirim">
        <span>
          İndirim Toplam :
          <b-link class="btn btn-sm btn-danger" @click="handlerPromosyonSil"><i class="fad fa-trash"></i></b-link>
        </span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.kupon_indirim | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center py-1 border-bottom"
        v-if="rezervasyon.rezervasyon_toplam.kupon_indirim > 0 || rezervasyon.rezervasyon_toplam.ekstra_toplam > 0"
      >
        <span>{{ rezervasyon.kdv_turu == 'dahil' ? 'Genel Toplam' : 'Ara Toplam' }} :</span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.ara_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center py-1 border-bottom" v-if="rezervasyon.kdv_turu == 'haric'">
        <span>
          KDV Toplam ( <b> % {{ rezervasyon.kdv_oran }} </b> ) :
        </span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.kdv_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center py-1 border-bottom" v-if="form.kdv_turu == 'haric'">
        <span>'Genel Toplam' :</span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.genel_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center py-1 border-bottom"
        v-if="rezervasyon.rezervasyon_toplam.on_odeme_toplam"
      >
        <span>
          Ön Ödeme Tutarı ( <b> % {{ rezervasyon.rezervasyon_toplam.on_odeme_oran }} </b> ) :
        </span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.on_odeme_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center py-1" v-if="form.odeme_tipi == 'on_odeme'">
        <span>Kalan Toplam :</span>
        <span class="text-primary font-weight-bold">
          {{ rezervasyon.rezervasyon_toplam.kalan_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
        </span>
      </div>
      <div class="py-1" v-if="form.depozito > 0">
        <!-- <b-alert show variant="warning" v-html="$t('depozitoAciklama', [depozitoFiyat(form.depozito)])" /> -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { ref, defineComponent, computed, onMounted, toRefs, watch } from '@vue/composition-api';
import moment from 'moment';
import store from '@/store';
import { exChange, kdv_toplam } from '@/libs/global';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: { flatPickr },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};

    const { form } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exchange = computed(() => store.getters.getExchange);
    expo.rezervasyon = computed(() => store.getters.getSorSatRezervasyon);
    expo.loaded = ref(false);

    expo.dateFormat = computed(() => {
      return (val) => {
        return moment(val).locale(expo.defaultDil.value).format('LL');
      };
    });

    expo.kampanyaFiyatHesapla = (price) => {
      const kampanya = form.value.kampanya;

      let fiyat = 0;
      if (kampanya.indirim_turu == 'yuzde') {
        const indirim = (price / 100) * kampanya.indirim_tutari;
        fiyat = price - indirim;
      } else {
        fiyat = price - kampanya.indirim_tutari;
      }

      return fiyat;
    };

    expo.priceFormat = computed(() => {
      return (val) => {
        let data = '';
        if (val) {
          val.map((el) => {
            if (el != null) {
              data += `${el.gun} gece ${global.exChange(parseFloat(expo.kampanyaFiyatHesapla(el.fiyat)), form.value.kur_turu).toFixed(2)} ${
                expo.exchange.value.banka_kodu
              } `;
            }
          });
        }

        return data;
      };
    });

    expo.depozitoFiyat = computed(() => {
      return (val) => {
        let data = `${exChange(parseFloat(val), form.value.kur_turu).toFixed(2)} ${expo.exchange.value.banka_kodu} `;
        return data;
      };
    });

    expo.handlerPromosyonSil = () => {
      expo.rezervasyon.value.promosyon_kodu = null;
      form.value.sabit_toplam.kupon_indirim = 0;
    };

    const handlerItemToplamHesapla = (product, index, odeme_yontemi) => {
      let yetiskin = 0,
        cocuk = 0,
        bebek = 0,
        toplam = 0,
        deposito = 0;

      const kampanya = product.kampanya;

      if (product.urun_tipi == 'tur') {
        const fiyat = product.bugun_satis_fiyat[0].fiyat;
        const pax = product.pax;

        Object.keys(pax).forEach((el) => {
          if (el == 'yetiskin') yetiskin = urun_toplam(pax[el], kampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
          if (el == 'cocuk') cocuk = urun_toplam(pax[el], kampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
          if (el == 'bebek') bebek = urun_toplam(pax[el], kampanyaFiyatHesapla(kampanya, parseFloat(fiyat[el])));
        });

        toplam = yetiskin + cocuk + bebek;
      } else if (
        product.urun_tipi == 'arac_kira' ||
        product.urun_tipi == 'villa' ||
        product.urun_tipi == 'yat' ||
        product.urun_tipi == 'transfer'
      ) {
        toplam = product.sabit_toplam.toplam;
      }

      const kdv_toplam = product.kdv_turu == 'haric' ? kdv_hesapla(toplam, product.kdv_oran) : 0;

      if (odeme_yontemi == 'kredi_karti') {
        deposito = toplam + kdv_toplam;
      } else {
        deposito = 0;
      }

      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.yetiskin = yetiskin;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.cocuk = cocuk;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.bebek = bebek;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.toplam = toplam;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.kdv_oran = product.kdv_oran;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.kdv_toplam = kdv_toplam;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.deposito = deposito;
      expo.rezervasyon.value.sepet_urunler[index].sabit_toplam.genel_toplam = toplam + kdv_toplam;

      if (expo.rezervasyon.value.secilen_kur != expo.rezervasyon.value.sabit_kur) {
        const kur = expo.rezervasyon.value.sabit_kur;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin = exChange(yetiskin, kur);
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.cocuk = exChange(cocuk, kur);
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.bebek = exChange(bebek, kur);
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.toplam = exChange(toplam, kur);
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_oran = product.kdv_oran;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_toplam = exChange(kdv_toplam, kur);
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.deposito = exChange(deposito, kur);
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.genel_toplam = exChange(toplam + kdv_toplam, kur);
      } else {
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.yetiskin = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.cocuk = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.bebek = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.toplam = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_oran = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.kdv_toplam = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.deposito = 0;
        expo.rezervasyon.value.sepet_urunler[index].kur_toplam.genel_toplam = 0;
      }

      return toplam;
    };

    expo.handlerHesapla = () => {
      let urun_toplam = form.value.sabit_toplam.toplam,
        ekstra_toplam = form.value.sabit_toplam.ekstra_toplam,
        kupon_indirim = form.value.sabit_toplam.kupon_indirim,
        taksit_fark = 0,
        ara_toplam = urun_toplam + ekstra_toplam + taksit_fark - kupon_indirim,
        kdv_toplam = form.value.kdv_turu == 'haric' ? kdv_hesapla(ara_toplam, form.value.kdv_oran) : 0,
        genel_toplam = ara_toplam + kdv_toplam,
        on_odeme_oran = form.value.on_odeme_oran,
        on_odeme_toplam = (genel_toplam / 100) * on_odeme_oran,
        kalan_toplam = genel_toplam - on_odeme_toplam;

      const pro = expo.rezervasyon.value.sepet_urunler;

      pro.forEach((el, index) => {
        handlerItemToplamHesapla(el, index, expo.rezervasyon.value.odeme_yontemi);
      });

      expo.rezervasyon.value.secilen_kur = expo.exchange.value.banka_kodu;
      expo.rezervasyon.value.secilen_kur_fiyat = expo.exchange.value.satis;

      expo.rezervasyon.value.rezervasyon_toplam.urun_toplam = urun_toplam;
      expo.rezervasyon.value.rezervasyon_toplam.ekstra_toplam = ekstra_toplam;
      expo.rezervasyon.value.rezervasyon_toplam.kupon_indirim = kupon_indirim;
      expo.rezervasyon.value.rezervasyon_toplam.taksit_fark = taksit_fark;
      expo.rezervasyon.value.rezervasyon_toplam.kdv_toplam = kdv_toplam;
      expo.rezervasyon.value.rezervasyon_toplam.ara_toplam = ara_toplam;
      expo.rezervasyon.value.rezervasyon_toplam.genel_toplam = genel_toplam;
      expo.rezervasyon.value.rezervasyon_toplam.on_odeme_oran = on_odeme_oran;
      expo.rezervasyon.value.rezervasyon_toplam.on_odeme_toplam = on_odeme_toplam;
      expo.rezervasyon.value.rezervasyon_toplam.kalan_toplam = kalan_toplam;

      if (expo.rezervasyon.value.secilen_kur != expo.rezervasyon.value.sabit_kur) {
        const kur = expo.rezervasyon.value.sabit_kur;
        expo.rezervasyon.value.kur_rezervasyon_toplam.urun_toplam = exChange(urun_toplam, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.ekstra_toplam = exChange(ekstra_toplam, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.kupon_indirim = exChange(kupon_indirim, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.taksit_fark = exChange(taksit_fark, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.kdv_toplam = exChange(kdv_toplam, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.ara_toplam = exChange(ara_toplam, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.genel_toplam = exChange(genel_toplam, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.on_odeme_oran = on_odeme_oran;
        expo.rezervasyon.value.kur_rezervasyon_toplam.on_odeme_toplam = exChange(on_odeme_toplam, kur);
        expo.rezervasyon.value.kur_rezervasyon_toplam.kalan_toplam = exChange(kalan_toplam, kur);
      } else {
        expo.rezervasyon.value.kur_rezervasyon_toplam.urun_toplam = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.ekstra_toplam = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.kupon_indirim = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.taksit_fark = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.kdv_toplam = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.ara_toplam = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.genel_toplam = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.on_odeme_oran = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.on_odeme_toplam = 0;
        expo.rezervasyon.value.kur_rezervasyon_toplam.kalan_toplam = 0;
      }
    };

    watch(
      expo.rezervasyon.value,
      (val) => {
        expo.handlerHesapla();
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
