<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold"> <i class="fad fa-shopping-basket" /> Ürün Bilgisi </b-card-header>
    <b-card-text class="table-responsive">
      <table class="table table-sm table-striped">
        <SepetCartItem
          v-for="(product, index) in sepet.sepet_urunler"
          :key="index"
          :product="product"
          :index="index"
          :sepet="sepet"
        />
      </table>
    </b-card-text>
  </b-card>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import SepetCartItem from './SepetCartItem.vue';
export default defineComponent({
  components: { SepetCartItem },
  props: {
    sepet: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
