import { render, staticRenderFns } from "./SepetDetayInfo.vue?vue&type=template&id=33b7f01a&scoped=true&"
import script from "./SepetDetayInfo.vue?vue&type=script&lang=js&"
export * from "./SepetDetayInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b7f01a",
  null
  
)

export default component.exports