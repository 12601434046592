<template>
  <b-card no-body class="rounded-0 mt-4">
    <b-card-header class="bg-transparent font-weight-bold">
      <i class="fad fa-wine-glass-alt" /> Ekstra Hizmet Bilgisi
    </b-card-header>
    <b-card-text class="p-1">
      <b-row>
        <b-col cols="12">
          <table class="table table-sm table-borderless table-hover w-100">
            <tbody>
              <UrunEkstraHizmetItem
                v-for="(hizmet, index) in sepet.sepet_urunler[0].ekstra_hizmetler"
                :hizmet="hizmet"
                :product="sepet.sepet_urunler[0]"
                :sepet="sepet"
                :key="index"
                @changeEkstraHizmet="handlerChangeEkstraHizmet($event, index)"
              />
              <tr>
                <td colspan="4" class="font-weight-bold text-right h5 mb-0">Ekstra Hizmet Toplam</td>
                <td class="font-weight-bold text-right h5 mb-0">
                  {{ toplamEkstraHizmetToplam | exc(sepet.sabit_kur) }} {{ exchange.banka_kodu }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import store from '@/store';
import UrunEkstraHizmetItem from './UrunEkstraHizmetItem.vue';
import { defineComponent, computed, toRefs } from '@vue/composition-api';
export default defineComponent({
  components: { UrunEkstraHizmetItem },
  props: {
    sepet: {
      type: Object,
      requrired: true,
    },
  },
  setup(props) {
    const expo = {};
    const { sepet } = toRefs(props);
    expo.exchange = computed(() => store.getters.getExchange);

    expo.handlerChangeEkstraHizmet = (event, index) => {
      sepet.value.sepet_urunler[0].ekstra_hizmetler[index] = event;

      store.commit('SET_SOR_SAT_REZERVASYON', sepet.value);
    };

    expo.toplamEkstraHizmetToplam = computed(() => {
      const hizmet = sepet.value.sepet_urunler[0].ekstra_hizmetler;

      let toplam = 0;

      hizmet.forEach((el) => {
        toplam += el.toplam_fiyat;
      });

      sepet.value.sepet_urunler[0].sabit_toplam.ekstra_toplam = toplam;
      return toplam;
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
