import { render, staticRenderFns } from "./UrunEkstraHizmetBilgileri.vue?vue&type=template&id=8504f9aa&scoped=true&"
import script from "./UrunEkstraHizmetBilgileri.vue?vue&type=script&lang=js&"
export * from "./UrunEkstraHizmetBilgileri.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8504f9aa",
  null
  
)

export default component.exports