<template>
  <b-card no-body class="rounded-0" v-if="sepet.diger_misafir.length">
    <b-card-header class="bg-white font-weight-bold"> <i class="fad fa-users"></i>Diğer Misafir Bilgisi... </b-card-header>
    <b-card-text class="p-2">
      <table class="table table-bordered table-striped table-sm">
        <thead class="font-weight-bold">
          <th>Ad</th>
          <th>Soyad</th>
          <th>Doğum Tarihi</th>
          <th>T.C & Pasaport No</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in sepet.diger_misafir" :key="index">
            <td>
              <validation-provider name="Ad" :rules="{ required: true }" v-slot="validationContext">
                <b-form-input
                  class="rounded-0"
                  v-model="sepet.diger_misafir[index].ad"
                  size="sm"
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </td>
            <td>
              <validation-provider name="Soyad" :rules="{ required: true }" v-slot="validationContext">
                <b-form-input
                  class="rounded-0"
                  v-model="sepet.diger_misafir[index].soyad"
                  size="sm"
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </td>
            <td>
              <validation-provider name="Doğum Tarihi" :rules="{ required: true }" v-slot="validationContext">
                <b-form-input
                  class="rounded-0"
                  v-model="sepet.diger_misafir[index].dogum_tarihi"
                  v-mask="'##.##.####'"
                  size="sm"
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </td>
            <td>
              <b-form-input class="rounded-0" v-model="sepet.diger_misafir[index].tc_pasaport" size="sm" />
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
  </b-card>
</template>

<script>
import { defineComponent, toRefs, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    sepet: { type: Object, required: true },
  },
  setup(props) {
    const expo = {};
    const { sepet } = toRefs(props);
    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    watch(
      sepet,
      (val) => {
        const diger_misafir = sepet.value.diger_misafir;
        const urun = sepet.value.sepet_urunler[0];
        const pax = urun.pax.yetiskin + urun.pax.cocuk - 1;

        if (diger_misafir == undefined || diger_misafir.length < pax) {
          sepet.value.diger_misafir.push({
            ad: null,
            soyad: null,
            dogum_tarihi: null,
            tc_pasaport: null,
          });
        } else if (diger_misafir.length > pax) {
          sepet.value.diger_misafir.pop();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
